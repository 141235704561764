<template>
    <r-e-dialog title="竞拍记录" class="layer-look-auction" show-footer :visible.sync="dialogVisible" top="10vh" width="1000px"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <r-e-table ref="tableRef" :data="tableData" :columns="tableColumns" :height="400">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </r-e-dialog>
</template>

<script>
    import {auctionColumns} from "../../../data"
    export default {
        name: "layer-look-auction",
        data() {
            return {
                tableColumns: auctionColumns,
                dialogVisible: false,
                tableData: [],
            };
        },
        components: {},
        async mounted() {},
        methods: {
            openDialog(data) {
                this.dialogVisible = true;
                let newestList=[
                    {
                        IdCard: "120120200202020202",
                        bidNo: "L1929",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 1800000,
                        state: "成交",
                        time: "2022-05-12 09:45:51",
                        uuid: 16559486295894,
                    },
                    {
                        IdCard: "120120200202020202",
                        bidNo: "L1928",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 1100000,
                        state: "出局",
                        time: "2022-05-12 09:44:37",
                        uuid: 16559486295895,
                    },
                    {
                        IdCard: "120120200202020202",
                        bidNo: "L1927",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 1000000,
                        state: "出局",
                        time: "2022-05-12 09:43:36",
                        uuid: 16559486295896,
                    },
                ];
                let historytList=[
                    {
                        IdCard: "120120200202020202",
                        bidNo: "G2565",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 800000,
                        state: "成交",
                        time: "2021-05-12 08:43:36",
                        uuid: 16559486295897,
                    },
                    {
                        IdCard: "120120200202020202",
                        bidNo: "G2564",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 700000,
                        state: "出局",
                        time: "2021-05-12 08:43:36",
                        uuid: 16559486295898,
                    },
                    {
                        IdCard: "120120200202020202",
                        bidNo: "G2563",
                        bidder: "张三",
                        companyName: "盛隆科技",
                        phone: "15012345678",
                        price: 600000,
                        state: "出局",
                        time: "2021-05-12 08:43:36",
                        uuid: 16559486295899,
                    },
                ];
                if(data.biddingProject==='2081') this.tableData = newestList;
                else this.tableData=historytList;
            },
            clickSubmit() {

            },
            clickCancel() {
                this.dialogVisible = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .layer-look-auction {
        .el-form .el-form-item{
            /deep/ .el-input{
                width: 220px;
            }
        }


    }
</style>
